import { createRoot } from 'react-dom/client';

const ReactRender = (component, selector) => {

    if (selector) {
        const root = createRoot(selector);
        root.render(component);
    }
    
};

export default ReactRender;
