import React from 'react';
import ReactRender from '../tools/react-render';

const SiteMenuEl = document.querySelector('[data-behaviour="site-menu"]');
if (SiteMenuEl) {
    const SiteMenu = React.lazy(() => import('./SiteMenu'));
    const menuId = SiteMenuEl.getAttribute('data-menu-id') ?? '';
    const menuIdLoggedIn =
        SiteMenuEl.getAttribute('data-menu-id-logged-in') ?? menuId;
    ReactRender(
        <SiteMenu menuId={menuId} menuIdLoggedIn={menuIdLoggedIn} />,
        SiteMenuEl,
    );
}

const ListingEl = document.querySelector('[data-behaviour="store-listing"]');
if (ListingEl) {
    const Listing = React.lazy(() => import('./Store/Listing'));
    let config = JSON.parse(ListingEl.getAttribute('data-config') ?? '{}');
    ReactRender(<Listing config={config} />, ListingEl);
}

const ProductEl = document.querySelector('[data-behaviour="store-product"]');
if (ProductEl) {
    const Product = React.lazy(() => import('./Store/Product'));
    let handle = ProductEl.getAttribute('data-handle') ?? null;
    ReactRender(<Product handle={handle} />, ProductEl);
}

const BasketEl = document.querySelector('[data-behaviour="store-basket"]');
if (BasketEl) {
    const Basket = React.lazy(() => import('./Store/Basket'));
    let appView = BasketEl.getAttribute('data-app-view') ?? null;
    ReactRender(<Basket appView={appView} />, BasketEl);
}

const AccountEl = document.querySelector('[data-behaviour="my-account"]');
if (AccountEl) {
    const Account = React.lazy(() => import('./Account/Account'));
    const searchParams = new URLSearchParams(window.location.search);
    const tab = searchParams.has('tab') ? searchParams.get('tab') : false;
    ReactRender(<Account tab={tab} />, AccountEl);
}

const StudioListingEl = document.querySelector(
    '[data-behaviour="studio-listing"]',
);
if (StudioListingEl) {
    const StudioListing = React.lazy(() => import('./Studios/Listing'));
    ReactRender(<StudioListing />, StudioListingEl);
}

const SignUpEl = document.querySelector('[data-behaviour="signup"]');
if (SignUpEl) {
    const SignUpWrapper = React.lazy(() => import('./Account/SignUpWrapper'));
    ReactRender(<SignUpWrapper />, SignUpEl);
}

const SignInEl = document.querySelector('[data-behaviour="signin"]');
if (SignInEl) {
    const Signin = React.lazy(() => import('./Account/Signin'));
    ReactRender(<Signin />, SignInEl);
}

const StudioClassesEl = document.querySelectorAll(
    '[data-behaviour="studio-classes"]',
);
if (StudioClassesEl.length > 0) {
    const StudioClasses = React.lazy(() => import('./Studios/Classes'));
    StudioClassesEl.forEach((studioClassEl) => {
        const type = studioClassEl.getAttribute('data-type') ?? 'simple';
        const providerId = studioClassEl.getAttribute('data-provider-id') ?? '';
        const appImage = studioClassEl.getAttribute('data-app-image') ?? '';
        const appTitle = studioClassEl.getAttribute('data-app-title') ?? '';
        const appText = studioClassEl.getAttribute('data-app-text') ?? '';
        const appiOSLink =
            studioClassEl.getAttribute('data-app-ios-link') ?? '';
        const appiOSImage =
            studioClassEl.getAttribute('data-app-ios-image') ?? '';
        const appAndroidLink =
            studioClassEl.getAttribute('data-app-android-link') ?? '';
        const appAndroidImage =
            studioClassEl.getAttribute('data-app-android-image') ?? '';
        const studioId = studioClassEl.getAttribute('data-studio-id') ?? '';
        ReactRender(
            <StudioClasses
                providerId={providerId}
                type={type}
                appImage={appImage}
                appTitle={appTitle}
                appText={appText}
                appiOSLink={appiOSLink}
                appiOSImage={appiOSImage}
                appAndroidLink={appAndroidLink}
                appAndroidImage={appAndroidImage}
                studioId={studioId}
            />,
            studioClassEl,
        );
    });
}

const StudioOffersEl = document.querySelectorAll(
    '[data-behaviour="studio-offers"]',
);
if (StudioOffersEl.length > 0) {
    const StudioOffers = React.lazy(() => import('./Studios/Offers'));
    StudioOffersEl.forEach((studioOffersEl) => {
        const type = studioOffersEl.getAttribute('data-type') ?? 'simple';
        const providerId =
            studioOffersEl.getAttribute('data-provider-id') ?? '';
        const appImage = studioOffersEl.getAttribute('data-app-image') ?? '';
        const appTitle = studioOffersEl.getAttribute('data-app-title') ?? '';
        const appText = studioOffersEl.getAttribute('data-app-text') ?? '';
        const appiOSLink =
            studioOffersEl.getAttribute('data-app-ios-link') ?? '';
        const appiOSImage =
            studioOffersEl.getAttribute('data-app-ios-image') ?? '';
        const appAndroidLink =
            studioOffersEl.getAttribute('data-app-android-link') ?? '';
        const appAndroidImage =
            studioOffersEl.getAttribute('data-app-android-image') ?? '';
        const studioName =
            studioOffersEl.getAttribute('data-studio-name') ?? '';
        const studioId = studioOffersEl.getAttribute('data-studio-id') ?? '';
        const studioVenueId =
            studioOffersEl.getAttribute('data-studio-venue-id') ?? '';
        ReactRender(
            <StudioOffers
                providerId={providerId}
                type={type}
                appImage={appImage}
                appTitle={appTitle}
                appText={appText}
                appiOSLink={appiOSLink}
                appiOSImage={appiOSImage}
                appAndroidLink={appAndroidLink}
                appAndroidImage={appAndroidImage}
                studioName={studioName}
                studioId={studioId}
                studioVenueId={studioVenueId}
            />,
            studioOffersEl,
        );
    });
}

const PricingTablesEl = document.querySelectorAll(
    '[data-behaviour="studio-pricing"]',
);
if (PricingTablesEl.length > 0) {
    const PricingTable = React.lazy(() => import('./Studios/PricingTable'));
    PricingTablesEl.forEach((pricingTableEl) => {
        const providerId =
            pricingTableEl.getAttribute('data-provider-id') ?? '';
        const studioId = pricingTableEl.getAttribute('data-studio-id') ?? '';
        ReactRender(
            <PricingTable providerId={providerId} studioId={studioId} />,
            pricingTableEl,
        );
    });
}

const StudioTimetableEl = document.querySelector(
    '[data-behaviour="studio-timetable"]',
);
if (StudioTimetableEl) {
    const StudioTimetable = React.lazy(() => import('./Studios/Timetable'));
    const providerId = StudioTimetableEl.getAttribute('data-provider-id') ?? '';
    const providerName =
        StudioTimetableEl.getAttribute('data-provider-name') ?? '';
    const venueId = StudioTimetableEl.getAttribute('data-venue-id') ?? '';
    const venueIds = StudioTimetableEl.getAttribute('data-venue-ids')
        ? StudioTimetableEl.getAttribute('data-venue-ids').split(',')
        : [];
    const studioId = StudioTimetableEl.getAttribute('data-studio-id') ?? '';
    ReactRender(
        <StudioTimetable
            providerId={providerId}
            providerName={providerName}
            venueId={venueId}
            venueIds={venueIds}
            studioId={studioId}
        />,
        StudioTimetableEl,
    );
}

const hotpodHomeListings = document.querySelectorAll(
    '[data-behaviour="hotpodhome-listing"]',
);
if (hotpodHomeListings.length > 0) {
    const HotpodHome = React.lazy(() => import('./HotpodHome'));
    hotpodHomeListings.forEach((hotpodHomeListing) => {
        let config = JSON.parse(
            hotpodHomeListing.getAttribute('data-config') ?? '{}',
        );
        ReactRender(<HotpodHome config={config} />, hotpodHomeListing);
    });
}

const hotpodHomePricingTableButtons = document.querySelectorAll(
    '[data-behaviour="hotpodhome-pricing-table-button"]',
);
if (hotpodHomePricingTableButtons.length > 0) {
    const PricingTableButton = React.lazy(
        () => import('./HotpodHome/PricingTableButton'),
    );
    hotpodHomePricingTableButtons.forEach((hotpodHomePricingTableBtn) => {
        const ctaTitle =
            hotpodHomePricingTableBtn.getAttribute('data-cta-title') ?? '';
        const membershipId =
            hotpodHomePricingTableBtn.getAttribute('data-membership-id') ?? '';
        const paymentPlanId =
            hotpodHomePricingTableBtn.getAttribute('data-payment-plan-id') ??
            '';
        ReactRender(
            <PricingTableButton
                ctaTitle={ctaTitle}
                membershipId={membershipId}
                paymentPlanId={paymentPlanId}
            />,
            hotpodHomePricingTableBtn,
        );
    });
}

const studioSidebars = document.querySelectorAll(
    '[data-behaviour="studio-sidebar"]',
);
if (studioSidebars.length > 0) {
    const StudioSidebar = React.lazy(() => import('./Account/StudioSidebar'));
    studioSidebars.forEach((studioSidebar) => {
        const providerId = studioSidebar.getAttribute('data-provider-id') ?? '';
        const venueId = studioSidebar.getAttribute('data-venue-id') ?? '';
        const studioId = studioSidebar.getAttribute('data-studio-id') ?? '';
        const studioName = studioSidebar.getAttribute('data-studio-name') ?? '';
        ReactRender(
            <StudioSidebar
                providerId={providerId}
                venueId={venueId}
                studioId={studioId}
                studioName={studioName}
            />,
            studioSidebar,
        );
    });
}
